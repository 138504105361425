.footer-main-new {
  margin-bottom: 2em;

  @media screen and (max-width: 750px) {
    display: flex;
    flex-direction: column;
  }

  .support-footer-section-area {
    h6 {
      font-weight: 700;
    }

    margin: 20px 45px 1px;

    @media screen and (max-width: 750px) {
      margin: 11px 2px 1px;
    }
  }

  .footer-section-area-mobile {
    @media screen and (max-width: 750px) {
      h6 {
        position: relative;
        left: 12%;
      }
    }
  }

  .footer-information-button-modals {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    button {
      text-transform: none;
      border: none;
      span{
        text-align: left;
      }

      &:focus {
        outline: none
      }
    }
  }

  .footer-payment-method-information {
    display: flex;

    @media screen and (min-width: 750px) {
      flex-wrap: wrap;
      max-width: 85%;
    }

    @media screen and (max-width: 750px) {
      position: relative;
      left: 15%;
    }


    .pay-image-footer {
      height: 30px;
      border-radius: 3px;
      margin-right: 0;
      object-fit: contain;
    }
  }

  .grid-footer-mobile {
    position: relative;
    left: 10%;
  }

  .footer-social-media-area {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 750px) {
      display: flex;
      flex-direction: row;
      position: relative;
      left: 15%;

      span {
        display: none;
      }
    }
    a {
      &:hover {
        color: inherit;
      }
    }
    
  }
}